<template>
  <div class="footer-inside">
    <div flex="1" class="box" direction="column">
      <div class="box" halign="between">
        <Logo
          :width="`21`"
          :longwidth="`113`"
        />
        <component
          :is="LanguageMenu"
          :fsize="`sm`"
          :flag="18"
        />
      </div>
      <div fsize="sm" color="md" pdt="2xs">
        {{ $t('introduce') }}
      </div>
      <hr class="hr" bg="5xs" mgt="2xl">
      <div class="box" pdt="2xl" gap="xs" valign="center" fsize="sm">
        <NuxtLink to="/" :title="$t('go-home')">
          <v-icon icon="mdi-home" color="primary" />
        </NuxtLink>
        <span color="3xs">
          <v-icon icon="mdi-slash-forward" fsize="sm" />
        </span>
        <NuxtLink to="/our" :title="$t('aboutus')">
          {{ $t('aboutus') }}
        </NuxtLink>
        <span color="3xs">
          <v-icon icon="mdi-slash-forward" fsize="sm" />
        </span>
        <NuxtLink to="/our/agreement" :title="$t('agreement')">
          {{ $t('agreement') }}
        </NuxtLink>
        <span color="3xs">
          <v-icon icon="mdi-slash-forward" fsize="sm" />
        </span>
        <NuxtLink to="/our/privacy" :title="$t('privacy')">
          {{ $t('privacy') }}
        </NuxtLink>
      </div>
      <div class="box" gap="3xs" pdt="3xl">
        <div v-for="(menu, index) in footerMenu" :key="index" flex="1">
          <v-btn :to="menu.path" :title="menu.title" variant="text" block bg="5xs" tpd="3xl">
            {{ menu.title }}
          </v-btn>
        </div>
      </div>
      <p fsize="xs" pdt="3xl">
        {{ $t('copyright-license') }}
        <span color="secondary" pdl="2xs">
          {{ $t('copyright-email-id') }}@{{ $t('copyright-email-domain') }}
        </span>
      </p>
      <p fsize="2xs" color="xs" pdt="2xs">
        {{ $t('copyright-ltd') }}
      </p>
      <p fsize="2xs" color="xs">
        {{ $t('copyright-allright') }}
      </p>
      <hr bg="5xs" mg="lg-y">
      <div class="box" halign="center">
        <component
          :is="SocialLink"
          :size="`x-small`"
          :gap="`md`"
          :bg="`4xs`"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia'
import { useAuthStore } from '~/store/auth'
// import LanguageMenu from '~/component/element/language-menu.vue'
// import SocialLink from '~/component/element/social-link.vue'

export default {
  setup () {

    // 글로벌 스토어
    let $i18n: any = ref(globalVariables.app.i18n)
    const { user } = storeToRefs(useAuthStore())

    // 메뉴 설정
    let footerMenu: any = ref(null)
    function updateFooterMenu () {
      footerMenu.value = user.value.login
                         ? globalFunctions.getTranslateText(globalVariables.footerMenu.login, $i18n.value)
                         : globalFunctions.getTranslateText(globalVariables.footerMenu.logout, $i18n.value)
    }
    updateFooterMenu()

    // 메모리 비우기
    function restore () {
      LanguageMenu.value = null
      SocialLink.value = null
      $i18n.value = null
      footerMenu.value = null
    }

    // 
    onBeforeUnmount (() => {
      restore()
    })

    // 비동기 함수를 만들어서 컴포넌트를 로딩 ---------------------------------------------------------
    const LanguageMenu: any = shallowRef(null)
    const SocialLink: any = shallowRef(null)
    const loadComponent = async () => {
      const LanguageMenu_load = await import('~/component/element/language-menu.vue')
      const SocialLink_load = await import('~/component/element/social-link.vue')
      LanguageMenu.value = LanguageMenu_load.default || LanguageMenu_load
      SocialLink.value = SocialLink_load.default || SocialLink_load
    }
    loadComponent()
    // ---------------------------------------------------------------------------------------------

    return {
      footerMenu,
      updateFooterMenu,

      // 컴포넌트
      LanguageMenu,
      SocialLink
    }
  },
  watch: {
    '$i18n.locale' () {
      this.updateFooterMenu()
    }
  }
}
</script>